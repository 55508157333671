import { Breadcrumbs, Paper } from '@material-ui/core';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PortalContext from '../context/portalContext';

const BreadCrumb = (props) => {

    const maxLabelSize = 20;
    const { pContext, } = useContext(PortalContext);

    const minimizeLabel = (label) => {
        if (!label || label === "") {
            return "...";
        }

        if (label.length > maxLabelSize) {
            return label.substring(0, 30) + "..."
        }
        else {
            return label;
        }

    }


    if (!pContext.disabled) {
        return (
            <div className="container">
                <Paper>

                    <Breadcrumbs aria-label="breadcrumb">
                        {
                            (pContext.paths || []).map((i, ix) =>
                                <Link color="inherit" to={i.path} key={ix}>
                                    {minimizeLabel(i[pContext.lang])}
                                </Link>

                            )
                        }

                    </Breadcrumbs>
                </Paper>
            </div>
        )
    }
    else {
        return (
            <div>
                {(pContext.paths || []).map((i, ix) =>
                    <p>
                        <Link color="inherit" to={i.path} key={ix}>
                            {minimizeLabel(i[pContext.lang])}
                        </Link>
                    </p>

                )}
            </div>
        )
    }
}

export default BreadCrumb;