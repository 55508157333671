import React from 'react';
import useStyles from '../styles/styles';
import { Card, CardContent } from '@material-ui/core';

const RootImage = (props) => {
    const styles = useStyles();



    return (
        <Card className={styles.newSquare} elevation={3}>
            <CardContent>
                <img alt="" src="/images/root.jpg" width="100%"></img>
            </CardContent>
        </Card>
    )
}

export default RootImage;