import React from 'react';
import DocumentHeader from '../controls/grid/DocumentHeader';
import DocumentRow from '../controls/grid/DocumentRow';
import IPGrid from '../controls/grid/IPGrid';

const DocumentList = ({ list, selectedDocs, selectFunc }) => {


    return (
        list &&
                <IPGrid rows={list}
                    header={() => <DocumentHeader></DocumentHeader>}
                    row={(item) => <DocumentRow item={item}
                        selectedDocs={selectedDocs} selectFunc={selectFunc}
                        ></DocumentRow>}>

                </IPGrid>

                
                
    )
}

export default DocumentList;