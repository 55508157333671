/* eslint-disable react/jsx-no-target-blank */
import { CircularProgress, Grid, Paper, Typography, Button } from '@material-ui/core';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useBreadCrumbs from '../hooks/useBreadCrumbs';
import Comm from '../comm/comm';
import useTrans from '../hooks/useTrans';
import DocumentTabs from '../parts/DocumentTabs';

const ObjectView = (props) => {
    const [obj, setObj] = useState();
    const [selectedDocs, setSelectedDocs] = useState({});
    const [back, setBack] = useState(false);
    const { addPath } = useBreadCrumbs();
    const trans = useTrans();

    const selectFunc = ({ id, title }) => {
        console.log(id, title);
        if (selectedDocs[id]) {
            delete selectedDocs[id];
        }
        else {
            selectedDocs[id] = title;
        }

        setSelectedDocs({ ...selectedDocs });
    }


    useEffect(() => {
        const fetchData = async () => {



            Comm.InstanceForPublic().get(`/backend/sis/public/polymorphic/artifact/page/active?artifactId=${props.match.params.objectId}`)
                .then(r => {

                    let d = r.data.rows[0];
                    d.classificationDocs = d.classificationDocs || [];
                    d.categoryDocs = d.categoryDocs || [];
                    d.otherDocuments = d.otherDocuments || [];
                    d.researchDocuments = d.researchDocuments || [];
                    d.openDocs = [];

                    d.categoryDocs.forEach(i => d.openDocs.push({ id: i.documentId, downloadable: i.downloadable, title: i.title }));
                    d.classificationDocs.forEach(i => d.openDocs.push({ id: i.documentId, downloadable: i.downloadable, title: i.title }));
                    d.otherDocuments.forEach(i => d.openDocs.push({ id: i.documentId, downloadable: i.downloadable, title: i.title }));
                    d.researchDocuments.forEach(i => d.openDocs.push({ id: i.documentId, downloadable: i.downloadable, title: i.title }));
                    d.protocols.forEach(p => {
                        p.documents.forEach(i => d.openDocs.push({ id: i.documentId, downloadable: i.downloadable, title: i.title }));

                    });
                    d.projects.forEach(p => {
                        p.documents.forEach(i => d.openDocs.push({ id: i.documentId, downloadable: i.downloadable, title: i.title }));

                    });
                    console.log("obj", d)

                    setObj(d);
                    addPath({
                        bg: d.name.bg,
                        en: d.name.en,
                    });

                });






        };
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    if (!obj) {
        return <CircularProgress />
    }

    if (back) {
        return <Redirect to="/Register"></Redirect>
    }

    return (
        <div className="container">
            <Paper>
                <Grid container spacing={3}>
                    <Grid item xs={1}>
                        <Button onClick={() => setBack(true)}><ArrowBackIcon></ArrowBackIcon></Button>
                    </Grid>

                    <Grid item xs={11}>
                        <Typography variant="h4">{trans("NKC")} {obj.name}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="h6">{trans("NKCNUM")}:</Typography>
                        <Typography variant="button">{obj.objectNumber}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="h6">{trans("ADDRESS")}: </Typography>
                        <Typography variant="button">{obj.main.address} {obj.main.habitat} </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="h6">{trans("LOCATION")}: </Typography>
                        <Typography variant="button">{obj.main.location}</Typography>
                    </Grid>


                    <Grid item xs={3}>
                        <Typography variant="h6">{trans("NKC3D")}: </Typography>
                        <Typography variant="button">{obj.NKCType3d}</Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant="h6">{trans("ART47CLASS")}: </Typography>
                        <Typography variant="button">{obj.main.art47Type}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h6">{trans("ART48CLASS")}: </Typography>
                        <Typography variant="button">{obj.main.art48Type}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h6">{trans("KEEPREGIME")}: </Typography>
                        <Typography variant="button">{obj.regime}</Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant="h6">{trans("CADASTRE")}: </Typography>
                        <Typography variant="button">{obj.cadastreData}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h6">{trans("OLDCADASTRE")}: </Typography>
                        <Typography variant="button">{obj.oldCadastreData}</Typography>
                    </Grid>
                    {
                        obj.coordinates ?
                            <Grid item xs={4}>
                                <Typography variant="h6">{trans("COORDINATES")}: </Typography>
                                {
                                    obj.coordinates.map(i => <p>{i.lat}, {i.lng}</p>)
                                }
                            </Grid>
                            :
                            <Grid item xs={4}>

                            </Grid>
                    }
                    <Grid item xs={8}>
                        <DocumentTabs object={obj} selectedDocs={selectedDocs} selectFunc={selectFunc}></DocumentTabs>
                    </Grid>
                    <Grid item xs={4}>
                        <b>{trans("SELECTEDDOCUMENTS")}</b>
                        <ul>
                            {

                                Object.keys(selectedDocs).map((i, ix) =>
                                    <li key={ix}>{selectedDocs[i]}</li>
                                )

                            }
                        </ul>
                        <b>{trans("SERVICES")}</b>
                        <p><a href={`https://ninkn.eu/jservice/service-one/secure`} target="_blank">Издаване на заверени копия от документи от Националния архивен фонд на недвижимите културни ценности</a></p>

                        <p><a href={`https://ninkn.eu/jservice/service-two/secure`} target="_blank">Издаване на удостоверения от Националния регистър за статута на недвижимите културни ценности</a></p>

                        <p><a href={`https://ninkn.eu/jservice/service-three/secure`} target="_blank">Изготвяне на становище във връзка със съгласуване на инвестиционни проекти и искания за намеси в защитени територии за опазване на културното наследство</a></p>

                        <p><a href={`https://ninkn.eu/jservice/service-four/secure`} target="_blank">Изготвяне на становище във връзка със съгласуване на планове за опазване и управление, устройствени схеми и планове и специфичните правила и нормативи към тях, както и заданията за тяхното изготвяне за защитени територии на недвижимото културно наследство</a></p>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

export default ObjectView;