import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import PortalContext from '../context/portalContext';
import useStyles from '../styles/styles';
import Tools from '../tools/tools';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Redirect } from 'react-router-dom';

const NewSquare = ({ content }) => {

    const { pContext, } = useContext(PortalContext);
    const styles = useStyles();
    const [showNewId, setShowNewId] = useState();

    if (showNewId) {
        return <Redirect to={"/NewPage/" + showNewId}></Redirect>
    }

    if (!pContext.disabled) {
        return (

            <Card className={styles.newSquare} elevation={3}>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <Typography variant="">{Tools.FormatDate(content.date, false)}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="h5">{content.title[pContext.lang]}</Typography>

                        </Grid>
                        <Grid item xs={1}>
                            <Button onClick={() => setShowNewId(content.id)}><VisibilityIcon></VisibilityIcon></Button>
                        </Grid>
                        <Grid item xs={3} className={styles.newImageBox}>
                            {/* <img alt="" src={props.content.image}></img> */}
                        </Grid>
                        <Grid item xs={9}>
                            <div dangerouslySetInnerHTML={{ __html: content.content[pContext.lang] }} />
                        </Grid>
                    </Grid>




                </CardContent>
            </Card>
        )
    }
    else {
        return (
            <>
                <p>{Tools.FormatDate(content.date, false)}</p>
                <p>{content.title[pContext.lang]}</p>
                <p><Button onClick={() => setShowNewId(content.id)}>Виж новина</Button></p>

                <div dangerouslySetInnerHTML={{ __html: content.content[pContext.lang] }} />

            </>

        )
    }
}
export default NewSquare;