import { Grid, Slider } from '@material-ui/core';
import React, { useState } from 'react';
import { MapContainer, Marker, TileLayer, Tooltip } from 'react-leaflet';

import mapUtils from '../map/mapUtils';


const MapCard = ({ objects }) => {
    const [zoom, setZoom] = useState(17);
    const [map, setMap] = useState(null)

    const changeZoom = (v) => {
        map.setZoom(v);
        setZoom(v);
    }

    const objectsWithCoordinates = objects.filter(o => !!o.main.coordinates);
    console.log("objectsWithCoordinates", objectsWithCoordinates);

    const firstCoordinates = objectsWithCoordinates.length > 0 ? objectsWithCoordinates[0].main.coordinates[0] : { lat: 42.696223, lng: 23.336456 };
    return (
        <Grid
            container
            spacing={3}
        >
            {
                objectsWithCoordinates.length > 0 &&
                <>
                    <Grid item xs={12}>
                        <MapContainer center={[firstCoordinates.lat, firstCoordinates.lng]} zoom={zoom}
                            whenCreated={setMap}
                            zoomControl={false}
                        >
                            <TileLayer
                                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />

                            {objects.map((point, index) =>

                                point.main.coordinates &&
                                <Marker
                                    key={index}
                                    icon={mapUtils.iconMarkup(index)}
                                    position={[
                                        point.main.coordinates[0].lat,
                                        point.main.coordinates[0].lng
                                    ]}

                                >
                                    <Tooltip>
                                        {point.name}
                                    </Tooltip>
                                </Marker>

                            )}
                        </MapContainer>
                    </Grid>
                    <Grid item xs={12}>
                        <Slider
                            value={zoom}
                            onChange={(e, v) => changeZoom(v)}
                            valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                            step={0.1}
                            min={0}
                            max={18}
                        />
                    </Grid>
                </>
            }
        </Grid>

    )
}

export default MapCard;