import { Grid, Typography } from '@material-ui/core';
import React from 'react';

const DocumentHeader = () => {
    return (

        <Grid container spacing={0}>
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>Документ</Typography>
            </Grid>
            
        </Grid>
    )
}

export default DocumentHeader;