const FakeData = {
    docTypes: [
        { docTypeId: 1, name: "Декларационна / информационна карта" },
        { docTypeId: 2, name: "Фиш" },
        { docTypeId: 3, name: "Преписка" },
        { docTypeId: 4, name: "Фотодокументация" },
        { docTypeId: 5, name: "Протокол" },
        { docTypeId: 6, name: "Доклад" },
        { docTypeId: 7, name: "Становище" },
        { docTypeId: 8, name: "Експертиза" },
        { docTypeId: 9, name: "Акт за собственост / Нотариален акт " },
        { docTypeId: 10, name: "Пълномощно" },
        { docTypeId: 11, name: "Чертежи " },
        { docTypeId: 12, name: "Други документи за обекта" },
        { docTypeId: 13, name: "Научно - мотивирано предложение" },
        { docTypeId: 's', name: "Преписка за съгласуване" },
        { docTypeId: 't', name: "Текстова част" },
        { docTypeId: 'g', name: "Графична част" },
        { docTypeId: 'a', name: "Акт за собственост" },
        { docTypeId: 'b', name: "Булстат" },
        { docTypeId: 'd', name: "Договор" },
        { docTypeId: 'p', name: "Пълномощно" },
        { docTypeId: 'op', name: "Опорен план" }
    ],
    registerSearchResult: [
        {
            id: 1,


            name: "Централни хали",
            number: "317-267",
            coordinates: {
                lat: 42.7001838679107,
                lon: 23.322044613419724
            },
        }
    ],
    classifications: [
        { classificationId: 1, title: "световно значение" },
        { classificationId: 2, title: "национално значение" },
        { classificationId: 3, title: "местно значение" },
        { classificationId: 4, title: "ансамблово значение" },
        { classificationId: 5, title: "за сведение" }

    ],
    categories: [
        { categoryId: 1, title: "археологически" },
        { categoryId: 2, title: "исторически" },
        { categoryId: 3, title: "архитектурно-строителни" },
        { categoryId: 4, title: "художествени" },
        { categoryId: 5, title: "урбанистични" },
        { categoryId: 6, title: "културен ландшафт" },
        { categoryId: 7, title: "парково и градинско изкуство" },
        { categoryId: 8, title: "етнографски" },
        { categoryId: 9, title: "културен маршрут" }

    ],
    ekatte: [
        {
            "key": "1",
            "label": "България",
            "children": [
                {
                    "key": "BLG",
                    "label": "Благоевград",
                    "children": [{
                        "key": "BLG01",
                        "label": "Банско",
                        "children": [{
                            "key": "02676", "label": "гр.Банско", "children": null
                        }, { "key": "17381", "label": "с.Гостун", "children": null }]




                    }]
                },
                {
                    "key": "SOF",
                    "label": "София - град",
                    "children": [{
                        "key": "SOF46",
                        "label": "Столична",
                        "children": [{
                            "key": "68134", "label": "гр. София", "children": null
                        }]



                    }]

                }
            ],
        }
    ],

    object: {


        number: "317-267",
        classificationId: "Национално значение",
        classificationDoc: { documentId: 5000, file: "reg-sof-001.pdf", date: "1998-04-03", title: "Държавен вестник", docTypeId: 12 },
        categoryId: "Архитектурно-строителна и художествена",
        categoryDoc: { documentId: 5000, file: "reg-sof-001.pdf", date: "1998-04-03", title: "Държавен вестник", docTypeId: 12 },
        regime: "",
        ekatte: "68134",
        fullAddress: "София бул.”Княгиня Мария Луиза”  № 23",
        address: "бул.”Княгиня Мария Луиза”  № 23",
        cadastreData: "68134.300.436",
        oldCadastreData: "68134.300.436",


        name: "Централни хали",
        objectNumber: "317-267",
        cadastreIdentifier: "68134.300.436",
        coordinates: [{
            lat: 42.7001838679107,
            lng: 23.322044613419724
        }],
        classificationDocs: [
            { documentId: 5000, file: "reg-sof-001.pdf", date: "1998-04-03", title: "Държавен вестник", docTypeId: 12 },
            { documentId: 5000, file: "reg-sof-001.pdf", date: "1998-04-03", title: "Държавен вестник", docTypeId: 12 }

        ],
        categoryDocs: [
            { documentId: 5000, file: "reg-sof-001.pdf", date: "1998-04-03", title: "Държавен вестник", docTypeId: 12 },
            { documentId: 5000, file: "reg-sof-001.pdf", date: "1998-04-03", title: "Държавен вестник", docTypeId: 12 }

        ],
        NKCType3d: "единичина",
        openDocs: [
            { documentId: 5000, file: "reg-sof-001.pdf", date: "1998-04-03", title: "Държавен вестник", docTypeId: 12 },
            { documentId: 5000, file: "reg-sof-001.pdf", date: "1998-04-03", title: "Държавен вестник", docTypeId: 12 }

        ],
        protocols: [

            {
                "protocolId": 1,
                "date": "1996-04-08",
                "title": "Протокол",
                "documents": [
                    {
                        "documentId": 1,
                        "file": "-sof028-317-267-31.pdf",
                        "date": "1996-04-08",
                        "title": "Протокол",
                        "docTypeId": 5
                    }
                ]
            },
            {
                "protocolId": 2,
                "date": "1996-04-08",
                "title": "Протокол",
                "documents": [
                    {
                        "documentId": 2,
                        "file": "-sof028-317-267-33.pdf",
                        "date": "1996-04-08",
                        "title": "Протокол",
                        "docTypeId": 5
                    }
                ]
            },
            {
                "protocolId": 3,
                "date": "1995-07-14",
                "title": "Протокол",
                "documents": [
                    {
                        "documentId": 3,
                        "file": "-sof028-317-267-39.pdf",
                        "date": "1995-07-14",
                        "title": "Протокол",
                        "docTypeId": 5
                    }
                ]
            },
            {
                "protocolId": 4,
                "date": "1995-02-14",
                "title": "Протокол",
                "documents": [
                    {
                        "documentId": 4,
                        "file": "-sof028-317-267-45.pdf",
                        "date": "1995-02-14",
                        "title": "Протокол",
                        "docTypeId": 5
                    }
                ]
            },
            {
                "protocolId": 5,
                "date": "1995-02-14",
                "title": "Протокол",
                "documents": [
                    {
                        "documentId": 5,
                        "file": "-sof028-317-267-46.pdf",
                        "date": "1995-02-14",
                        "title": "Протокол",
                        "docTypeId": 5
                    }
                ]
            },
            {
                "protocolId": 6,
                "date": "1992-10-23",
                "title": "Протокол",
                "documents": [
                    {
                        "documentId": 6,
                        "file": "-sof028-317-267-48.pdf",
                        "date": "1992-10-23",
                        "title": "Протокол",
                        "docTypeId": 5
                    }
                ]
            },
            {
                "protocolId": 7,
                "date": "1992-09-18",
                "title": "Протокол",
                "documents": [
                    {
                        "documentId": 7,
                        "file": "-sof028-317-267-51.pdf",
                        "date": "1992-09-18",
                        "title": "Протокол",
                        "docTypeId": 5
                    }
                ]
            },
            {
                "protocolId": 8,
                "date": "1991-01-29",
                "title": "Протокол",
                "documents": [
                    {
                        "documentId": 8,
                        "file": "-sof028-317-267-52.pdf",
                        "date": "1991-01-29",
                        "title": "Протокол",
                        "docTypeId": 5
                    }
                ]
            },
            {
                "protocolId": 9,
                "date": "1989-03-24",
                "title": "Протокол",
                "documents": [
                    {
                        "documentId": 9,
                        "file": "-sof028-317-267-56.pdf",
                        "date": "1989-03-24",
                        "title": "Протокол",
                        "docTypeId": 5
                    }
                ]
            }

        ],
        researchDocs: [
            { documentId: 1001, file: '-sof028-317-267-1.pdf', docTypeId: 12 },
            { documentId: 1002, file: '-sof028-317-267-2.pdf', docTypeId: 1 },
            { documentId: 1003, file: '-sof028-317-267-3.pdf', docTypeId: 3, date: '2011-07-18' },
            { documentId: 1004, file: '-sof028-317-267-4.pdf', docTypeId: 3, date: '2011-06-10' },
            { documentId: 1005, file: '-sof028-317-267-5.pdf', docTypeId: 12 },
            { documentId: 1006, file: '-sof028-317-267-6.pdf', docTypeId: 3, date: '2005-08-22' },
            { documentId: 1007, file: '-sof028-317-267-7.pdf', docTypeId: 3, date: '2011-05-13' },
            { documentId: 1008, file: '-sof028-317-267-8.pdf', docTypeId: 3, date: '2005-08-22' },
            { documentId: 1009, file: '-sof028-317-267-9.pdf', docTypeId: 3, date: '2000-08-22' },
            { documentId: 1010, file: '-sof028-317-267-10.pdf', docTypeId: 7, date: '2000-05-25' },
            { documentId: 1011, file: '-sof028-317-267-11.pdf', docTypeId: 3, date: '2000-02-01' },
            { documentId: 1012, file: '-sof028-317-267-12.pdf', docTypeId: 3, date: '1999-06-16' },
            { documentId: 1013, file: '-sof028-317-267-13.pdf', docTypeId: 6, date: '1999-06-04' },
            { documentId: 1014, file: '-sof028-317-267-14.pdf', docTypeId: 3, date: '1999-06-02' },
            { documentId: 1015, file: '-sof028-317-267-15.pdf', docTypeId: 3, date: '1999-04-27' },
            { documentId: 1016, file: '-sof028-317-267-16.pdf', docTypeId: 7, date: '1999-05-25' },
            { documentId: 1017, file: '-sof028-317-267-17.pdf', docTypeId: 7, date: '1999-05-14' },
            { documentId: 1018, file: '-sof028-317-267-18.pdf', docTypeId: 3, date: '1999-02-12' },
            { documentId: 1019, file: '-sof028-317-267-19.pdf', docTypeId: 3, date: '1999-02-12' },
            { documentId: 1020, file: '-sof028-317-267-20.pdf', docTypeId: 3, date: '1999-01-19' },
            { documentId: 1021, file: '-sof028-317-267-21.pdf', docTypeId: 3, date: '1998-10-15' },
            { documentId: 1022, file: '-sof028-317-267-22.pdf', docTypeId: 3, date: '1998-10-15' },
            { documentId: 1023, file: '-sof028-317-267-23.pdf', docTypeId: 3, date: '1998-11-04' },
            { documentId: 1024, file: '-sof028-317-267-24.pdf', docTypeId: 3, date: '1998-09-01' },
            { documentId: 1025, file: '-sof028-317-267-25.pdf', docTypeId: 3, date: '1998-09-11' },
            { documentId: 1026, file: '-sof028-317-267-26.pdf', docTypeId: 3 },
            { documentId: 1027, file: '-sof028-317-267-27.pdf', docTypeId: 7, date: '1999-02-02' },
            { documentId: 1028, file: '-sof028-317-267-28.pdf', docTypeId: 7 },
            { documentId: 1029, file: '-sof028-317-267-29.pdf', docTypeId: 7, date: '1999-02-03' },
            { documentId: 1030, file: '-sof028-317-267-30.pdf', docTypeId: 3, date: '1997-11-27' },
            { documentId: 1031, file: '-sof028-317-267-32.pdf', docTypeId: 12, date: '1996-04-05' },
            { documentId: 1032, file: '-sof028-317-267-34.pdf', docTypeId: 3, date: '1996-04-17' },
            { documentId: 1033, file: '-sof028-317-267-35.pdf', docTypeId: 3, date: '1996-03-28' },
            { documentId: 1034, file: '-sof028-317-267-36.pdf', docTypeId: 3, date: '1996-03-14' },
            { documentId: 1035, file: '-sof028-317-267-37.pdf', docTypeId: 3, date: '1996-02-21' },
            { documentId: 1036, file: '-sof028-317-267-38.pdf', docTypeId: 3, date: '1995-07-27' },
            { documentId: 1037, file: '-sof028-317-267-40.pdf', docTypeId: 12 },
            { documentId: 1038, file: '-sof028-317-267-41.pdf', docTypeId: 12 },
            { documentId: 1039, file: '-sof028-317-267-42.pdf', docTypeId: 3, date: '1995-07-14' },
            { documentId: 1040, file: '-sof028-317-267-43.pdf', docTypeId: 3, date: '1995-05-18' },
            { documentId: 1041, file: '-sof028-317-267-44.pdf', docTypeId: 3, date: '1995-05-18' },
            { documentId: 1042, file: '-sof028-317-267-47.pdf', docTypeId: 3, date: '1995-05-18' },
            { documentId: 1043, file: '-sof028-317-267-49.pdf', docTypeId: 3, date: '1992-10-12' },
            { documentId: 1044, file: '-sof028-317-267-50.pdf', docTypeId: 12, date: '1992-11-16' },
            { documentId: 1045, file: '-sof028-317-267-53.pdf', docTypeId: 12 },
            { documentId: 1046, file: '-sof028-317-267-54.pdf', docTypeId: 3, date: '1991-01-29' },
            { documentId: 1047, file: '-sof028-317-267-55.pdf', docTypeId: 7, date: '1990-10-30' },
            { documentId: 1048, file: '-sof028-317-267-57.pdf', docTypeId: 3, date: '1970-11-30' },
            { documentId: 1049, file: '-sof028-317-267-58.pdf', docTypeId: 11 },
            { documentId: 1050, file: '-sof028-317-267-59.pdf', docTypeId: 12, date: '1999-01-12' },
            { documentId: 1051, file: '-sof028-317-267-60.pdf', docTypeId: 12 },
            { documentId: 1052, file: '-sof028-317-267-61.pdf', docTypeId: 12 },
            { documentId: 1053, file: '-sof028-317-267-62.pdf', docTypeId: 12 },
            { documentId: 1054, file: '-sof028-317-267-63.pdf', docTypeId: 12 },
            { documentId: 1055, file: '-sof028-317-267-64.pdf', docTypeId: 4 },
        ]
    },

    newsShort: [
        {
            newId: 1,
            title: {
                bg: "Сиела завърши успешно проект за НИНКН",
                en: "Ciela successfully delivered the project for NINKN"
            },
            date: '2021-03-31',
            image: "https://ciela.bg/wp-content/uploads/2020/07/cielaLogo.png",
            text: {
                bg: `<p>1111111111Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>`,
                en: `<p>111111111Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>`
            }

        },
        {
            newId: 2,
            title: {
                bg: "Сиела завърши успешно проект за НИНКН 2",
                en: "Ciela successfully delivered the project for NINKN"
            },
            date: '2021-03-31',
            image: "https://images.app.goo.gl/PNVXCHCYQVoA7XwL6",
            text: {
                bg: `<p>22222222222Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>`,
                en: `<p>222222222Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>`
            }

        },
        {
            newId: 3,
            title: {
                bg: "Сиела завърши успешно проект за НИНКН 3",
                en: "Ciela successfully delivered the project for NINKN"
            },
            date: '2021-03-31',
            image: "https://images.app.goo.gl/PNVXCHCYQVoA7XwL6",
            text: {
                bg: `<p>33333333Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>`,
                en: `<p>33Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>`
            }

        },
    ],
    translations: {
        "portaltitle": {
            bg: "Публичен портал",
            en: "Public Portal"
        },
        "news": {
            bg: "Новини",
            en: "News"
        },
    },
    mainText: {

        title: {
            bg: "НИНКН",
            en: "NINKN"
        },
        image: "https://ciela.bg/wp-content/uploads/2020/07/cielaLogo.png",
        text: {
            bg: `<p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...
            <img src="https://ciela.bg/wp-content/uploads/2020/07/cielaLogo.png" alt=""></img>
            </p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>`,
            en: `<p>111111111Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>`
        }

    },
    news: {
        more: true,
        news: [
            {
                newId: 1,
                title: {
                    bg: "Сиела завърши успешно проект за НИНКН",
                    en: "Ciela successfully delivered the project for NINKN"
                },
                date: '2021-03-31',
                image: "https://ciela.bg/wp-content/uploads/2020/07/cielaLogo.png",
                text: {
                    bg: `<p>1111111111Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>`,
                    en: `<p>111111111Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>`
                }

            },
            {
                newId: 2,
                title: {
                    bg: "Сиела завърши успешно проект за НИНКН 2",
                    en: "Ciela successfully delivered the project for NINKN"
                },
                date: '2021-03-31',
                image: "https://images.app.goo.gl/PNVXCHCYQVoA7XwL6",
                text: {
                    bg: `<p>22222222222Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>`,
                    en: `<p>222222222Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>`
                }

            },
            {
                newId: 3,
                title: {
                    bg: "Сиела завърши успешно проект за НИНКН 3",
                    en: "Ciela successfully delivered the project for NINKN"
                },
                date: '2021-03-31',
                image: "https://images.app.goo.gl/PNVXCHCYQVoA7XwL6",
                text: {
                    bg: `<p>33333333Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>`,
                    en: `<p>33Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>`
                }

            },
        ]
    },
    oneNew: {

        newId: 7,
        title: {
            bg: "Сиела завърши успешно проект за НИНКН",
            en: "Ciela successfully delivered the project for NINKN"
        },
        date: '2021-03-31',
        image: "https://ciela.bg/wp-content/uploads/2020/07/cielaLogo.png",
        text: {
            bg: `<p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>`,
            en: `<p>111111111Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>`
        }

    },
    oneFreeText: {

        id: 7,
        title: {
            bg: "За нас",
            en: "About Us"
        },
        text: {
            bg: `<p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>
                <p>Няма никой, който да обича болката сама по себе си, да я търси и да иска да я изпитва, просто защото е болка...</p>`,
            en: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>`
        }

    },
    mainMenu: [
        {
            bg: "Начало",
            en: "Homepage",
            type: 1,
        },
        {
            bg: "История",
            en: "History",
            type: 4,
            pageId: 1

        },
        {
            bg: "Регистър",
            en: "Register",
            type: 2,
        },
        {
            bg: "Новини",
            en: "News",
            type: 3,
        },
        {
            bg: "Проверка на статус на услуга",
            en: "Webservice check",
            type: 5,
        }
    ],







}

export default FakeData;