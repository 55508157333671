import { Card, CardContent, CircularProgress, Grid, Paper, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import PortalContext from '../context/portalContext';
import Comm from '../comm/comm';
import Tools from '../tools/tools';
import useBreadCrumbs from '../hooks/useBreadCrumbs';

const FreeTextPage = (props) => {
    const { pContext, } = useContext(PortalContext);
    const [content, setContent] = useState();
    const { addPath } = useBreadCrumbs();


    useEffect(() => {
        const fetchData = async () => {
            Comm.Instance().get(`page/${props.match.params.id}`)
                .then(r => {
                    r.data.content.bg = Tools.fixLinksToFiles(r.data.content.bg);
                    r.data.content.en = Tools.fixLinksToFiles(r.data.content.en);
                    r.data.content.bg = Tools.fixExternalLinks(r.data.content.bg);
                    r.data.content.en = Tools.fixExternalLinks(r.data.content.en);

                    addPath({
                        bg: r.data.title.bg,
                        en: r.data.title.en,
                    });
                    setContent(r.data);
                });

        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match.params.id]);

    if (!content) {
        return <CircularProgress />
    }
    if (!pContext.disabled) {
        return (
            <div className="container">
                <Paper>
                    <Card elevation={3}>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">{content.title[pContext.lang]}</Typography>

                                </Grid>
                                <Grid item xs={12}>
                                    <div dangerouslySetInnerHTML={{ __html: content.content[pContext.lang] }} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Paper>
            </div>

        )
    }
    else {
        return (

            <div dangerouslySetInnerHTML={{ __html: content.content[pContext.lang] }} />

        )
    }
}

export default FreeTextPage;