import { useContext } from "react";
import PortalContext from "../context/portalContext";
import { useLocation } from 'react-router-dom';


const lsBCKey = "BCKEY";
const emptyPath = [{
    bg: "Начало",
    en: "Start",
    path: "/"
}];


const useBreadCrumbs = () => {

    const { pContext, setPContext } = useContext(PortalContext);
    const loc = useLocation();


    const addPath = (item) => {
        let paths = emptyPath;
        if (item) {
            let ls = localStorage.getItem(lsBCKey);
            if (ls) {
                paths = JSON.parse(ls);
            };
            item.path = loc.pathname;


            let ix = paths.findIndex(el => el.path.toLowerCase() === item.path.toLowerCase());
            if (ix !== -1) {
                paths.splice(ix + 1);
            }
            else {
                paths.push(item);
            }
        }
        localStorage.setItem(lsBCKey, JSON.stringify(paths))
        pContext.paths = paths;
        setPContext({ ...pContext });

    }

    return { addPath }
}

export default useBreadCrumbs;