import React, { useContext } from 'react';
import PortalContext from '../context/portalContext';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Button } from '@material-ui/core';

const Lang = (props) => {
    const { pContext, setPContext } = useContext(PortalContext);

    const changeContext = (evnt, value) => {
        pContext.lang = value;
        setPContext({ ...pContext });
    }

    if (!pContext.disabled) {
        return (
            <ToggleButtonGroup
                value={pContext.lang}
                exclusive
                onChange={changeContext}
            >
                <ToggleButton value="bg" aria-label="left aligned">
                    БГ
                            </ToggleButton>
                <ToggleButton value="en" aria-label="left aligned">
                    EN
                            </ToggleButton>
            </ToggleButtonGroup>


        )
    }
    else {
        return (
            <>
                <Button onClick={() => changeContext(null, "bg")}>БГ</Button><br />
                <Button onClick={() => changeContext(null, "en")}>EN</Button><br />
            </>


        )
    }
}

export default Lang;