import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';

import React, { useEffect, useState } from 'react';

const IPGrid = ({ rows, header, row }) => {
    let [page, setPage] = useState(1);
    let [pageSize, setPageSize] = useState(10);
    let [displayRows, setDisplayRows] = useState([]);

    let [pages, setPages] = useState([]);

    useEffect(() => {
        setDisplayRows((!rows && rows.length === 0) ? [] : rows.slice((page - 1) * pageSize, page * pageSize))
        let ps = [];
        let maxPage = parseInt((rows || []).length / pageSize) + ((rows || []).length % pageSize > 0 ? 1 : 0);
        for (let i = 1; i <= maxPage; i++) {
            ps.push(i);
        }
        setPages(ps);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows, pageSize, page]);

    useEffect(() => {
        setPage(1);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize])

    return (

        <Grid container spacing={3}>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <InputLabel>Покажи</InputLabel>
                    <Select
                        value={pageSize}
                        onChange={(e) => setPageSize(e.target.value)}
                    >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                {rows && <span>Открити са {rows.length} елемента</span>}
            </Grid>
            <Grid item xs={12}>
                {header()}
            </Grid>

            {
                displayRows.map((item, index) =>
                    <Grid item xs={12} key={index}>
                        {row(item)}
                    </Grid>
                )
            }
            <Grid item xs={12}>
                {pages.map((item) =>
                    // eslint-disable-next-line eqeqeq
                    item == page ? <b>{page}</b> :
                        <Button onClick={() => setPage(item)}>{item}</Button>

                )}
            </Grid>

        </Grid>
    )
}

export default IPGrid;