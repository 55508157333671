import { Button, CircularProgress, Grid, Paper } from '@material-ui/core';
import React, { useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import TB from '../controls/form/TB';
import FakeData from '../fakedata/FakeData';
import MapCard from '../cards/mapCard';
import { useEffect } from 'react';
import ObjectSmallCard from '../cards/objectSmallCard';
import EkatteDDL from '../controls/form/EkatteDDL';
import useBreadCrumbs from '../hooks/useBreadCrumbs';
import Comm from '../comm/comm';
import useTrans from '../hooks/useTrans';

const Register = (props) => {
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [address, setAddress] = useState("");
    const [cadastreData, setCadastreData] = useState("");
    const [objects, setObjects] = useState();
    const [classifications, setClassifications] = useState();
    const [categories, setCategories] = useState();
    const [progress, setProgress] = useState(false);
    const [ekatte, setEkatte] = useState();
    const [cityId, setCityId] = useState();
    const { addPath } = useBreadCrumbs();
    const trans = useTrans();

    useEffect(() => {
        const fetchData = async () => {
            setObjects([]);//setObjects(FakeData.registerSearchResult)
            setClassifications(FakeData.classifications);
            setCategories(FakeData.categories);
            Comm.InstanceForPublic().get("backend/secure/ekatte-tree/1")
                .then(r => {
                    setEkatte([r.data]);
                });



        };
        fetchData();
        addPath({
            bg: "Регистър",
            en: "Register",
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const Search = () => {
        setProgress(true);
        Comm.InstanceForPublic().post("/backend/sis/public/artifact/search", {
            "address": address === "" ? null : address,
            "cadastreData": cadastreData === "" ? null : cadastreData,
            "ekatteId": cityId === "0" ? null : cityId,
            "title": name === "" ? null : name,
            "objectNumber": number === "" ? null : number,
            "page": {
                page: 0,
                size: 100000
            }
        })
            .then(r => setObjects(r.data.rows))
            .finally(() => setProgress(false));

    }


    if (progress || !classifications || !categories || !ekatte) {
        return <CircularProgress />
    }

    return (
        <div className="container">
            <Paper>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <Grid container spacing={3}>
                            <Grid item xs={10}>
                                <TB label={trans("name")} value={name} change={(v) => setName(v)}></TB>
                            </Grid>
                            <Grid item xs={1}>
                                <Button onClick={Search}><SearchIcon></SearchIcon></Button>
                            </Grid>
                            <Grid item xs={12}>
                                <TB label={trans("NKCNUM")} value={number} change={(v) => setNumber(v)}></TB>
                            </Grid>
                            <Grid item xs={12}>
                                <EkatteDDL label={trans("city")} ekatte={ekatte} change={(v) => setCityId(v)}></EkatteDDL>
                            </Grid>
                            <Grid item xs={12}>
                                <TB label={trans("address")} value={address} change={(v) => setAddress(v)}></TB>
                            </Grid>
                            <Grid item xs={12}>
                                <TB label={trans("cadastre")} value={cadastreData} change={(v) => setCadastreData(v)}></TB>
                            </Grid>
                            {/* <Grid item xs={12}>
                                <DDL label="Класификация" value={classification} items={classifications} change={(v) => setClassification(v)} idfield="classificationId" labelfield="title"></DDL>
                            </Grid>
                            <Grid item xs={12}>
                                <DDL label="Категория" value={category} items={categories} change={(v) => setCategory(v)} idfield="categoryId" labelfield="title"></DDL>
                            </Grid> */}
                        </Grid>
                    </Grid>
                    <Grid item xs={8}>
                        {
                            objects && <MapCard objects={objects}>

                            </MapCard>
                        }

                    </Grid>

                    {
                        objects &&
                        objects.map((o, i) =>
                            <Grid item xs={4} key={o.id}>
                                <ObjectSmallCard object={o} number={i}>

                                </ObjectSmallCard>
                            </Grid>
                        )
                    }
                </Grid>
            </Paper>
        </div>
    )
}

export default Register;