import React, { useContext } from 'react';
import PortalContext from '../context/portalContext';
import { Button } from '@material-ui/core';
import AccessibleIcon from '@material-ui/icons/Accessible';

const Disabled = (props) => {
    const { pContext, setPContext } = useContext(PortalContext);

    const changeContext = () => {
        pContext.disabled = !(pContext.disabled || false);
        setPContext({ ...pContext });
    }


    return (
        <Button onClick={changeContext}>{pContext.disabled ? "Възстановяване на нормалния изглед" : <AccessibleIcon></AccessibleIcon>}</Button>


    )
}

export default Disabled;