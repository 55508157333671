import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    gridHeader: {
        background: '#000000',
        color: 'white',
        marginTop: '0px',
        padding: '1px'
    },
    gridGroupHeader: {
        background: '#552233',
        color: 'white',
        margin: '0px',
        padding: '1px'
    },
    gridSubGroupHeader: {
        background: '#119922',
        color: 'white',
        margin: '0px',
        padding: '1px'
    },
    newSquare: {
        minHeight: '200px',
        maxHeight: '200px',
        overflow: 'hidden',
        paddingBottom: '20px',
    },
    newImageBox: {
        overflow: 'hidden',
    }
});

export default useStyles;