import React, { useContext } from 'react';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import NavMenu from './NavMenu';
import { Grid } from '@material-ui/core';
import Lang from '../controls/Lang';
import PortalContext from '../context/portalContext';
import BreadCrumb from '../controls/BreadCrumb';
import Disabled from '../controls/Disabled';
import useTrans from '../hooks/useTrans';


const Layout = (props) => {
    const [open, setOpen] = React.useState(false);
    const { pContext } = useContext(PortalContext);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const trans = useTrans();


    if (!pContext.disabled) {
        return (
            <>

                <AppBar position="relative" color={'transparent'}>

                    <Toolbar className="ml-5">
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                        >
                            <Grid item lg={2} xs={12}>

                            </Grid>
                            <Grid item lg={1} xs={12}>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={handleDrawerOpen}
                                    edge="start"
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Grid>
                            <Grid item xs={12} lg={1}>
                                <img src="images/bulgarian-gerb-small.png" alt="" style={{ 'maxWidth': '100%' }} />
                            </Grid>
                            
                            <Grid item xs={12} lg={4}>
                                <h1>{trans("ninkn")}</h1>
                            </Grid>
                            
                            <Grid item xs={12} lg={1}><Lang></Lang></Grid>
                            <Grid item xs={12} lg={1}><Disabled></Disabled></Grid>
                        </Grid>



                    </Toolbar>



                </AppBar>

                <main>

                    <div className="mt-3">
                        <BreadCrumb></BreadCrumb>
                    </div>

                    <div className="mt-3">
                        {props.children}
                    </div>
                    <Drawer
                        variant="persistent"
                        anchor="left"
                        open={open}
                    >
                        <NavMenu handleDrawerClose={handleDrawerClose}></NavMenu>
                    </Drawer>


                </main>


            </>
        );
    }
    else {
        return (<>
            {pContext.translations.portaltitle &&
                pContext.translations.portaltitle[pContext.lang]
            }
            <br />
            <Lang></Lang>
            <br />
            <Disabled></Disabled>
            <br />
            <NavMenu handleDrawerClose={handleDrawerClose}></NavMenu>
            <br />
            <BreadCrumb></BreadCrumb>
            <br />
            {props.children}
        </>)
    }

}

export default Layout;