import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import uuidv4 from 'react-uuid';

const EkatteDDL = (props) => {

    const [selection, setSelection] = useState([]);

    const id = uuidv4();


    const rows = useMemo(() => {
        let rs = selection.length === 0 ? props.ekatte : selection[selection.length - 1].children;
        return rs || [];
    }, [selection, props.ekatte])


    const label = useMemo(() => {
        let lbl = props.label + " ";
        for (let el of selection) {
            lbl += el.label + " ";
        }
        return lbl;

    }, [selection, props.label])

    const select = (id) => {
        if (id === "..") {
            selection.splice(selection.length-1);
            setSelection([...selection]);
            let key = selection.length === 0 ? "0" : selection[selection.length - 1].key;
            props.change(key);
        }
        else {
            let element = selection.length === 0 ? props.ekatte.find(x => x.key === id) : selection[selection.length - 1].children.find(x => x.key === id);
            selection.push(element);
            setSelection([...selection]);
            props.change(element.key);
        }

    }



    return (
        <FormControl fullWidth>
            <InputLabel id={id}>{label}</InputLabel>
            <Select
                labelId={id}
                value="0"
                onChange={(e) => select(e.target.value)}

            >
                {
                    selection.length === 0 ?
                        <MenuItem value={"0"}>&nbsp;</MenuItem> :
                        <MenuItem value={".."}>..</MenuItem>
                }

                {
                    rows.map(i => <MenuItem value={i.key} key={i.key}>{i.label}</MenuItem>)
                }
            </Select>
        </FormControl>
    )
}

export default EkatteDDL;
