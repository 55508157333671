import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import PortalContext from '../context/portalContext';


const FreeText = (props) => {
    const { pContext, } = useContext(PortalContext);
    console.log("props", props)

    if (!pContext.disabled) {
        return (

            <Card elevation={3}>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {
                                props.content.title && <Typography variant="h5">{props.content.title[pContext.lang]}</Typography>
                            }

                        </Grid>
                        <Grid item xs={12}>
                            <div dangerouslySetInnerHTML={{ __html: props.content.content[pContext.lang] }} />
                        </Grid>
                    </Grid>




                </CardContent>
            </Card>

        )
    } else {
        return (
            <>
                <p>
                    {
                        props.content.title && <Typography variant="h5">{props.content.title[pContext.lang]}</Typography>
                    }
                </p>
                <p>
                    <div dangerouslySetInnerHTML={{ __html: props.content.content[pContext.lang] }} />
                </p>
            </>

        )
    }
}

export default FreeText;