/* eslint-disable react/jsx-no-target-blank */
import { Button, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import LazyImage from '../LazyImage';
import ZoomInIcon from '@material-ui/icons/ZoomIn';

const DocumentRow = ({ item, deleteFunc, selectedDocs, selectFunc }) => {
    let [prev, setPrev] = useState(false);


    return (

        <Grid container spacing={0}>
            <Grid item xs={10}>

                <a href={process.env.REACT_APP_PUBLIC + "backend/sis/public/file?reference=" + item.documentId} target="_blank">{item.title}</a>
                {item.aka && item.aka.map((i, ix) => <Typography variant="subtitle2" key={ix}>{i}</Typography>)}
            </Grid>
            <Grid iten xs={1}>
                <LazyImage id={item.documentId} open={prev} onClose={() => setPrev(false)} title={item.title || item["file-name"]}></LazyImage>
                <Button onClick={() => setPrev(true)}><ZoomInIcon></ZoomInIcon></Button>

            </Grid>
            <Grid item xs={1}>

                <Button onClick={() => selectFunc({ id: item.documentId, title: item.title })} >

                    {
                        !selectedDocs[item.documentId] ? <CheckBoxOutlineBlankIcon></CheckBoxOutlineBlankIcon> : <CheckBoxIcon></CheckBoxIcon>
                    }
                </Button>



            </Grid>
        </Grid>
    )
}

export default DocumentRow;