import PortalContext from "../context/portalContext";
import { useContext } from "react";

const useTrans = () => {
    const { pContext } = useContext(PortalContext);

    const trans = (key) => {
        key = key.toUpperCase();
        if (pContext.translations[key] && pContext.translations[key][pContext.lang]) {
            return pContext.translations[key][pContext.lang];
        }
        else {
            return "!!! няма превод";
        }
    }

    return trans;
}
export default useTrans;