import { InputLabel, MenuItem, Select } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DocumentHeader from '../controls/grid/DocumentHeader';
import DocumentRow from '../controls/grid/DocumentRow';
import IPGrid from '../controls/grid/IPGrid';
import uuidv4 from 'react-uuid';
import useTrans from '../hooks/useTrans';


const FilterDocumentList = ({ object, listName, idField, labelField, addLabelField, docsField, selectedDocs, selectFunc }) => {

    let [value, setValue] = useState("0")
    let [list, setList] = useState([]);
    const trans = useTrans();


    const id = uuidv4();

    useEffect(() => {
        if (!object[listName]) {
            return;
        }
        let item = object[listName].find(x => x[idField] === value);
        setList(item ? item[docsField] : []);




        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);



    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <InputLabel id={id}>{trans("CHOOSE")}</InputLabel>
                <Select
                    value={value}
                    onChange={(e) => setValue(e.target.value)}

                >
                    <MenuItem value="0">&nbsp;</MenuItem>
                    {
                        object[listName] &&
                        object[listName].map((i, ix) => <MenuItem value={i[idField]} key={i[idField]}>{`${i[labelField]} ${addLabelField ? i[addLabelField] : ''}`}</MenuItem>)
                    }
                </Select>


            </Grid>
            <Grid item xs={12}>
                {
                    list && list.length > 0 &&
                    <IPGrid rows={list}
                        header={() => <DocumentHeader></DocumentHeader>}
                        row={(item) => <DocumentRow item={item}
                            selectedDocs={selectedDocs} selectFunc={selectFunc}
                        ></DocumentRow>}>

                    </IPGrid>
                }

            </Grid>
        </Grid>
    )
}

export default FilterDocumentList;