import { Paper } from '@material-ui/core';
import React, { useState } from 'react';
import useTrans from '../hooks/useTrans';
import DocumentList from './DocumentList';
import FilterDocumentList from './FilterDocumentList';

const DocumentTabs = ({ object, selectedDocs, selectFunc }) => {

    let [page, setPage] = useState(0);
    const trans = useTrans();

    const tabClass = (id) => {
        return page === id ? "btn btn-info" : "btn btn-secondary";
    }

    return (

        <Paper>
            <div className="tabcontainer">
                {object.categoryDocs && object.categoryDocs.length > 0 && <button className={tabClass(1)} onClick={() => setPage(1)} type="button">{trans("CATEGORIZATIONDOCS")}</button>}&nbsp;
                {object.classificationDocs && object.classificationDocs.length > 0 && <button className={tabClass(2)} onClick={() => setPage(2)} type="button">{trans("CLASIFICATIONDOCS")}</button>}&nbsp;
                {object.researchDocuments && object.researchDocuments.length > 0 && <button className={tabClass(3)} onClick={() => setPage(3)} type="button">{trans("RESEARCHDOCS")}</button>}&nbsp;
                {object.otherDocuments && object.otherDocuments.length > 0 && <button className={tabClass(4)} onClick={() => setPage(4)} type="button">{trans("OTHERDOCS")}</button>}&nbsp;
                {object.projects && object.projects.length > 0 && <button className={tabClass(5)} onClick={() => setPage(5)} type="button">{trans("PROJECTS")}</button>}&nbsp;
                {object.protocols && object.protocols.length > 0 && <button className={tabClass(6)} onClick={() => setPage(6)} type="button">{trans("PROTOCOLS")}</button>}
            </div>
            {
                page === 1 &&
                <DocumentList list={object.categoryDocs} selectedDocs={selectedDocs} selectFunc={selectFunc}></DocumentList>
            }
            {
                page === 2 &&
                <DocumentList list={object.classificationDocs} selectedDocs={selectedDocs} selectFunc={selectFunc}></DocumentList>
            }
            {
                page === 3 &&
                <DocumentList list={object.researchDocuments} selectedDocs={selectedDocs} selectFunc={selectFunc}></DocumentList>
            }
            {
                page === 4 &&
                <DocumentList list={object.otherDocuments} selectedDocs={selectedDocs} selectFunc={selectFunc}></DocumentList>
            }
            {
                page === 5 &&
                <FilterDocumentList object={object} listName="projects" idField="projectId" labelField="title" docsField="documents" addLabelField="year" selectedDocs={selectedDocs} selectFunc={selectFunc}></FilterDocumentList>
            }
            {
                page === 6 &&
                <FilterDocumentList object={object} listName="protocols" idField="protocolId" labelField="title" docsField="documents" selectedDocs={selectedDocs} selectFunc={selectFunc}></FilterDocumentList>
            }
        </Paper>

    )
}

export default DocumentTabs;