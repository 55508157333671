import React from 'react';

import { divIcon } from 'leaflet'
import { renderToStaticMarkup } from 'react-dom/server';

class mapUtils {
    distance = (p1, p2) => {
        const R = 6371e3; // metres
        const φ1 = p1.lat * Math.PI / 180; // φ, λ in radians
        const φ2 = p2.lat * Math.PI / 180;
        const Δφ = (p2.lat - p1.lat) * Math.PI / 180;
        const Δλ = (p2.lon - p1.lon) * Math.PI / 180;

        const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const d = Math.round((R * c) / 1000); // in km
        return d;

    }

    pinColor = (num) => {
        const colors = [
            '#eb4034',
            '#3443eb',
            '#6e34eb',
            '#34ebc0',
            '#b734eb',
            '#ff2359',
            '#e5eb34',
            '#34abeb',
            '#eb34b4',
            '#6eeb34',
        ]

        return colors[num % 9];

    }

    iconMarkup =(num)=>{
        return divIcon({
            html: renderToStaticMarkup(<span style={{ fontSize: 20, color: this.pinColor(num), border:'1px solid', background:'#ffffff' }} >{++num}</span>),
        });
        
    } 
    

}

export default new mapUtils();