import { Card, CardContent, CircularProgress, Grid } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import Comm from '../comm/comm';
import PortalContext from '../context/portalContext';
import useBreadCrumbs from '../hooks/useBreadCrumbs';

const NA = (props) => {
    const [content, setContent] = useState();
    const { addPath } = useBreadCrumbs();
    const { pContext, } = useContext(PortalContext);

    useEffect(() => {
        const fetchData = async () => {
            Comm.InstanceForNormact().get(`/api/ciela/GetDoc?naId=${props.match.params.id}`)
                .then(r => {
                    setContent(r.data);
                    addPath({
                        bg: "Нормативен акт",
                        en: "Norm Act",
                    });
                });

        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!content) {
        return <CircularProgress />
    }

    if (!pContext.disabled) {
        return (
            <div class="container">
                <Card elevation={3}>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div dangerouslySetInnerHTML={{ __html: content }} />
                            </Grid>
                        </Grid>




                    </CardContent>
                </Card>
            </div>
        )
    }
    else {
        return (

            <div dangerouslySetInnerHTML={{ __html: content }} />

        )
    }
}

export default NA;