import { Button, CircularProgress, Grid, Paper, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import TB from '../controls/form/TB';

const ServiceCheck = (props) => {
    const [num, setNum] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState();

    const loadStatus = () => {
        setLoading(true);
        fetch(`https://ninkn.eu/CheckDocStatus/api/docstatus/cardstatus/${num||"0"}/${password||"0"}`)
            .then(response => response.json())
            .then(data => {
                console.log("data", data);
                setStatus(data);
                setLoading(false);
            })
            .catch(error => {
                setStatus(error);
            });

        // Comm.InstanceForAIS().get(`${num}/${password}`)
        //     .then(r => {
        //         console.log(r.data);
        //         setStatus(r.data);
        //         setLoading(false);
        //     });
    }

    if (loading) {
        return <CircularProgress />
    }

    return (
        <div className="container">
            <Paper>
                <Grid container spacing={3}>
                    <Grid item xs={5}>
                        <TB label="Входящ номер" value={num} change={(v) => setNum(v)}></TB>
                    </Grid>
                    <Grid item xs={5}>
                        <TB label="Код за достъп" value={password} change={(v) => setPassword(v)}></TB>
                    </Grid>
                    <Grid item xs={2}>
                        <Button onClick={loadStatus}><SearchIcon></SearchIcon></Button>
                    </Grid>
                    {
                        status &&
                        <Grid item xs={8}>
                            <Typography variant="h3">{status}</Typography>

                        </Grid>
                    }
                    {
                        status &&
                        status.notFound &&
                        <Grid item xs={8}>
                            <Typography variant="h3">Невалидна комбинация входящ номер и код за достъп</Typography>

                        </Grid>
                    }

                </Grid>
            </Paper>
        </div>
    )
}

export default ServiceCheck;