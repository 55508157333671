import { Card, CardContent, CircularProgress, Grid, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import PortalContext from '../context/portalContext';
import Tools from '../tools/tools';
import Comm from '../comm/comm';
import useBreadCrumbs from '../hooks/useBreadCrumbs';

const NewPage = (props) => {
    const { pContext, } = useContext(PortalContext);
    const [content, setContent] = useState();
    const { addPath } = useBreadCrumbs();

    useEffect(() => {
        const fetchData = async () => {

            Comm.Instance().get(`news/${props.match.params.newId}`)
                .then(r => {
                    r.data.content.bg = Tools.fixLinksToFiles(r.data.content.bg);
                    r.data.content.en = Tools.fixLinksToFiles(r.data.content.en);
                    addPath({
                        bg: r.data.title.bg,
                        en: r.data.title.en,
                    });
                    setContent(r.data);
                });
        };
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!content) {
        return <CircularProgress />
    }


    if (!pContext.disabled) {
        return (
            <div className="container">
                <Card elevation={3}>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={1}>
                                <Typography variant="">{Tools.FormatDate(content.date, false)}</Typography>
                            </Grid>
                            <Grid item xs={11}>
                                <Typography variant="h5">{content.title[pContext.lang]}</Typography>

                            </Grid>
                            <Grid item xs={12}>
                                <img alt="" src={content.image} style={{ 'max-width': '100%', 'float': 'left' }}></img>
                                <div dangerouslySetInnerHTML={{ __html: content.content[pContext.lang] }} />
                            </Grid>
                        </Grid>




                    </CardContent>
                </Card>
            </div>
        )
    }
    else {
        return (
            <>
                <p>{Tools.FormatDate(content.date, false)}</p>
                <p>{content.title[pContext.lang]}</p>
                <div dangerouslySetInnerHTML={{ __html: content.content[pContext.lang] }} />
            </>
        )
    }


}

export default NewPage;