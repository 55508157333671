import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import PortalContext from '../context/portalContext';
import Comm from '../comm/comm';
import useBreadCrumbs from '../hooks/useBreadCrumbs';


const NavMenu = (props) => {

    const [menu, setMenu] = useState([]);
    const { pContext } = useContext(PortalContext);
    const { addPath } = useBreadCrumbs();


    const getPath = (item) => {
        switch (item.type) {
            case 1: return '/';
            case 2: return '/Register';
            case 3: return 'http://95.43.224.27/eDeloPage';
            case 4: return `/FreeTextPage/${item.pageId}`;
            case 5: return '/News';
            default: return '/';

        }
    }

    const handleClick = () => {
        props.handleDrawerClose();
        addPath(null);
    }
    useEffect(() => {
        Comm.Instance().get("menu")
            .then(r => setMenu(r.data));
    }, [])


    if (!pContext.disabled) {
        return (

            <div className="service-nav">
                <div>
                    <IconButton onClick={props.handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                {
                    menu && menu.map((i, ix) =>
                        i.type == 3 ? <a href={getPath(i)} target="_tab">{i[pContext.lang]}</a> :
                            <Link to={getPath(i)} onClick={handleClick} key={ix}>{i[pContext.lang]}</Link>
                    )
                }


            </div>
        );
    }
    else {
        return (
            menu && menu.map((i, ix) =>
                <p><Link to={getPath(i)} onClick={handleClick} key={ix}>{i[pContext.lang]}</Link></p>
            )
        )
    }

}

export default NavMenu;
