

class Tools {


    ValidateEmail = (mail) => {
        return (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail));
    }

    FormatDate = (date, withTime = true) => {
        var d = new Date(date),
            month = ('' + (d.getMonth() + 1)).padStart(2, '0'),
            day = ('' + d.getDate()).padStart(2, '0'),
            year = ('' + d.getFullYear()).padStart(2, '0'),
            hour = ('' + d.getHours()).padStart(2, '0'),
            mins = ('' + d.getMinutes()).padStart(2, '0');



        if (withTime) {
            return `${day}.${month}.${year} ${hour}:${mins}`;
        }
        else {
            return `${day}.${month}.${year}`;
        }
    }

    NormalizeDate = (date) => {
        if (!date) {
            return null;
        }
        var d = new Date(date),
            month = ('' + (d.getMonth() + 1)).padStart(2, '0'),
            day = ('' + d.getDate()).padStart(2, '0'),
            year = ('' + d.getFullYear()).padStart(2, '0');





        return `${year}-${month}-${day}`;
    }

    fixLinksToFiles(content) {
        content = content ? content.replaceAll(/="[^"]+?file\?document_id=([0-9]+)"/g, `="${process.env.REACT_APP_FILES}file?document_id=$1"`) : "";
        return content;
    }

    fixExternalLinks(content) {
        content = content ? content.replaceAll(/(href="http[^"]+?")/g, `$1 target="_blank"`) : "";
        return content;
    }






}
export default new Tools();