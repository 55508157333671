import { Grid, Paper, Step, StepButton, Stepper, Typography } from '@material-ui/core';
import React, { useContext, useState, useEffect } from 'react';
import Comm from '../comm/comm';
import PortalContext from '../context/portalContext';
import useBreadCrumbs from '../hooks/useBreadCrumbs';
import FreeText from '../parts/FreeText';
import NewSquare from '../parts/NewSquare';
import RootImage from '../parts/RootImage';
import Tools from '../tools/tools';

const Main = (props) => {


    const [activeStep, setActiveStep] = useState(0);
    const [news, setNews] = useState([]);
    const [mainText, setMainText] = useState();
    const { pContext, } = useContext(PortalContext);
    const { addPath } = useBreadCrumbs();


    useEffect(() => {
        Comm.Instance().get("news/latest/3")
            .then(r => {
                setNews(r.data);
            });

        Comm.Instance().get(`page/main`).then(
            r => {
                console.log("main", r.data.content);
                r.data.content.bg = Tools.fixLinksToFiles(r.data.content.bg);
                r.data.content.en = Tools.fixLinksToFiles(r.data.content.en);
                setMainText(r.data);

            }
        )

        addPath(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!pContext.disabled) {
        return (
            <div className="container">
                <Paper>
                    <Grid item xs={12}>
                        <RootImage></RootImage>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={10}>
                            <Typography variant="h3">{pContext.translations.news && pContext.translations.news[pContext.lang]}</Typography>
                        </Grid>
                        <Grid item xs={12} lg={2}>

                            <Stepper nonLinear activeStep={activeStep}>
                                {news.map((n, ix) => (
                                    <Step key={n.newId}>
                                        <StepButton onClick={() => setActiveStep(ix)}>

                                        </StepButton>
                                    </Step>
                                ))}
                            </Stepper>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                news.length > 0 && <NewSquare content={news[activeStep]}></NewSquare>
                            }

                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    {mainText && <FreeText content={mainText}></FreeText>}
                </Paper>
            </div>
        )
    } else {
        return (
            <>
                <p>{pContext.translations.news && pContext.translations.news[pContext.lang]}</p>
                {news.map((n, ix) => (
                    <p><NewSquare content={news[ix]}></NewSquare></p>
                ))}
                <p>{mainText && <FreeText content={mainText}></FreeText>}</p>
            </>

        )
    }
}

export default Main;