import { Button, Grid, Paper, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import PortalContext from '../context/portalContext';
import FakeData from '../fakedata/FakeData';
import NewSquare from '../parts/NewSquare';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import useBreadCrumbs from '../hooks/useBreadCrumbs';

const News = (props) => {
    const { pContext } = useContext(PortalContext);
    const [news, setNews] = useState();
    const { addPath } = useBreadCrumbs();

    useEffect(() => {
        const fetchData = async () => {
            setNews(FakeData.news)

        };
        fetchData();
        addPath({
            bg: "Новини",
            en: "News",
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getMore = () => {
        const more = { ...FakeData.moreNews };
        news.more = more.more;
        news.news = [...news.news, ...more.news];

        setNews({...news});
    }

    return (
        <div className="container">
            <Paper>
                <Grid container spacing={3}>
                    <Grid item xs={10}>
                        <Typography variant="h3">{pContext.translations.news&&pContext.translations.news[pContext.lang]}</Typography>
                    </Grid>
                    {
                        news && news.news.map(n => <Grid item xs={12} key={n.newId}>
                            <NewSquare content={n}></NewSquare>
                        </Grid>)
                    }

                </Grid>
                {
                    news && news.more && <Button onClick={() => getMore()}><MoreHorizIcon></MoreHorizIcon></Button>
                }
            </Paper>
        </div>
    )
}

export default News;