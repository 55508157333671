import React, { useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Button, Paper } from '@material-ui/core';
import mapUtils from '../map/mapUtils';
import { Redirect } from 'react-router-dom';


const ObjectSmallCard = ({ object, number }) => {
    const [openId, setOpenId] = useState();
    console.log("object", object);
    if (openId) {
        return (<Redirect to={`/Object/${openId}`}></Redirect>)
    }
    return (
        <Paper>
            <span style={{ "color": mapUtils.pinColor(number) }}>{object.main.number}. {object.main.name}</span><br />
            <small>{(object.main.municipality + " ") || ""}{(object.main.habitat + " ") || ""}{(object.main.location + " ") || ""}{(object.main.address + " ") || ""}</small>
            <Button onClick={() => setOpenId(object.id)}><SearchIcon></SearchIcon></Button>
        </Paper>
    )



}

export default ObjectSmallCard;