import React, { useEffect, useState } from 'react';
import Layout from './components/Layout';
import { RecoilRoot, } from 'recoil';
import CssBaseline from '@material-ui/core/CssBaseline'
import theme from './theme'
import { CircularProgress, ThemeProvider } from '@material-ui/core';
import Register from './components/Register';
import ObjectView from './components/ObjectView';
import Main from './components/Main';
import PortalContext from './context/portalContext';
import ServiceCheck from './components/ServiceCheck';
import News from './components/News';
import NewPage from './components/NewPage';
import FreeTextPage from './components/FreeTextPage';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Comm from './comm/comm';
import NA from './components/NA';

const App = props => {
    const [pContext, setPContext] = useState({
        lang: 'bg'
    });


    useEffect(() => {
        const fetchData = async () => {
            Comm.Instance().get("labels").then(r => {
                pContext.translations = r.data;
                setPContext({ ...pContext });
            });
        };
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    if (!pContext.translations) {
        return <CircularProgress />;
    }
    const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
    

    return (
        <PortalContext.Provider value={{ pContext, setPContext }}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <RecoilRoot>
                    <BrowserRouter basename={baseUrl}>
                        <Switch>

                            <Layout>
                                <Route path='/' component={Main} exact />
                                <Route path='/Register' component={Register} exact />
                                <Route path='/Object/:objectId' component={ObjectView} exact />
                                <Route path='/News' component={News} exact />
                                <Route path='/NewPage/:newId' component={NewPage} exact />
                                <Route path='/FreeTextPage/:id' component={FreeTextPage} exact />
                                <Route path='/ServiceCheck' component={ServiceCheck} exact />
                                <Route path='/NA/:id' component={NA} exact />


                            </Layout>

                        </Switch>
                    </BrowserRouter>

                </RecoilRoot>
            </ThemeProvider>
        </PortalContext.Provider>
    )



}

export default App;
